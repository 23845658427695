<script>
import moment from 'moment';

export default {
  name: 'report-layout',
  props: {
    financing: Object,
    settings: Object
  },
  computed: {
    formattedEncerramentoDaOferta() {
      if (this.financing && this.financing.fields && this.financing.fields['Encerramento da Oferta']) {
        return this.formatDate(this.financing.fields['Encerramento da Oferta'].value, 'LL');
      } else {
        return '';
      }
    },
    formattedValorTotalCaptado() {
      if (this.financing && this.financing.totais && this.financing.totais.valor_total) { 
        return this.financing.totais.valor_total.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
      } else {
        return '';
      }
    }
  },
  methods: {
    formatDate(date, format) {
      return date ? moment(date).format(format) : '';
    },
    formattedValue (valor) {
      'R$ ' +
          valor
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
         
  }
};
</script>

<template>
  <div class="content">
    <div class="texts">
      <p class="span-header">RESOLUÇÃO CVM Nº, 88 DE 27 DE ABRIL DE 2022</p>
      <p 
        style="
          text-align: center;  
          font-size: 12px;
          color: #000;
          text-transform: uppercase;
          font-weight: 500;
          line-height: 1.6;
          text-align: center;
        ">
         ANEXO G À RESOLUÇÃO CVM Nº 88, DE 27 DE ABRIL DE 2022 <br />
         INFORMAÇÕES SOBRE O INÍCIO E O ENCERRAMENTO DE OFERTA PÚBLICA DE VALORES MOBILIÁRIOS<br />
         EMITIDOS POR SOCIEDADE EMPRESÁRIA DE PEQUENO PORTE REALIZADA COM DISPENSA DE REGISTRO<br />
         NA COMISSÃO DE VALORES MOBILIÁRIOS
      </p>
    </div>

    <div 
      class="item" 
      style="border-top: none; border: 1px solid #000; padding: 4px 8px; border-bottom: none; line-height: 1.6"
    >
      <span class="title" style="text-transform: uppercase;">Sociedade empresária de pequeno porte</span><br />
      <span class="title">
        Nome: {{ ( financing && financing.dados_complementares.empresa ) 
        ? financing.dados_complementares.empresa.nome : '' }}
      </span><br />
      <span class="title">
        CNPJ: {{ ( financing && financing.dados_complementares.empresa ) 
        ? financing.dados_complementares.empresa.documento.numero : '' }}
      </span><br />
      <span class="title">
        Tipo societário: {{ ( financing && financing.fields && financing.fields['Tipo Societário'] ) 
        ? financing.fields['Tipo Societário'].value : '' }}
      </span><br />
      <span class="title">
        Endereço da sede: {{ ( financing && financing.dados_complementares.empresa ) 
        ? financing.dados_complementares.empresa.endereco.rua : '' }} 
        {{ ( financing && financing.dados_complementares.empresa ) 
        ? financing.dados_complementares.empresa.endereco.numero : '' }}, 
        {{ ( financing && financing.dados_complementares.empresa ) 
        ? financing.dados_complementares.empresa.endereco.cidade : '' }}/{{ ( financing && financing.dados_complementares.empresa ) 
        ? financing.dados_complementares.empresa.endereco.estado : '' }}
      </span><br />
      <span class="title">
        Nome do representante legal: {{ ( financing && financing.dados_complementares.empresa ) 
        ? financing.dados_complementares.empresa.dados_complementares.responsavel.nome : '' }}</span
      >
      <span class="title">
        Telefone para contato: {{ ( financing && financing.dados_complementares.empresa ) 
        ? financing.dados_complementares.empresa.dados_complementares.responsavel.telefone.telefone : '' }}
      </span><br />
      <span class="title">
        E-mail: {{ ( financing && financing.dados_complementares.empresa ) 
        ? financing.dados_complementares.empresa.dados_complementares.responsavel.email.email : '' }}
      </span><br />
      <span class="title">
        Página da sociedade na rede mundial de computadores (se houver): {{ ( financing && financing.fields && financing.fields['Site'] ) 
        ? financing.fields['Site'].value : '' }}</span
      >
      <span class="title">
        Receita bruta no último exercício social: {{ ( financing && financing.fields && financing.fields['Receita Bruta Último Exercício'] ) 
        ? financing.fields['Receita Bruta Último Exercício'].value : 0 | currency }}
      </span><br />
    </div>
    
    <div style="border-top: none; border: 1px solid #000; padding: 4px 8px; line-height: 1.6">
      <span class="title" style="text-transform: uppercase;">Dados da oferta</span> <br />
      
      <span class="title">
        Quantidade de valores mobiliários objeto da oferta: {{ ( financing && financing.fields && financing.fields['Quantidade Inicial de Ações'] ) 
        ? financing.fields['Quantidade Inicial de Ações'].value : 0 }}
      </span><br />
      <span class="title">
        Descrição do valor mobiliário ofertado especificando a sua natureza como:
      </span><br />
      <ul class="value-desc">
        <li style="list-style: none;">Ações ou valores mobiliários representativos de capita</li>
        <li style="list-style: none;">Valores mobiliários representativos de dívida não conversível</li>
        <li style="list-style: none;">Valores mobiliários representativos de dívida conversível em ações</li>
      </ul>
      <span class="title">
        Preço unitário: {{ ( financing && financing.fields && financing.fields['Valor da Ação'] ) 
        ? financing.fields['Valor da Ação'].value : 0 | currency }}
      </span><br />
      <span class="title">
        Valor alvo máximo da oferta: {{ ( financing && financing.totais ) 
        ? financing.totais.valor_meta_formatado : '' }}
      </span><br />
      <span class="title">
        Data de início da oferta: {{ ( financing && financing.dates ) ? formatDate(financing.dates.started_at, 'LLL') : '' }}
        </span><br />
      <span class="title">
        Identificação da Plataforma Eletrônica de Investimento
        Participativo: {{ settings.platformIdentifier }}
        </span><br />
      <span class="title">Página da plataforma na rede mundial de computadores: {{ settings.site }}</span><br />
    </div>

    <div style="border-top: none; border: 1px solid #000; padding: 4px 8px; line-height: 1.6">
      <span style="color: #000; text-transform: uppercase; font-size: 12px">DADOS SOBRE O ENCERRAMENTO DA OFERTA</span> <br />
      <span class="title">Data de encerramento da oferta: {{ formattedEncerramentoDaOferta }}</span> <br />
      <span class="title">Valor total captado: {{ formattedValorTotalCaptado }}</span> <br />
      <span class="title">Dados finais de colocação, indicando o número de investidores participantes da oferta e os montantes totais
        adquiridos conforme as seguintes categorias: {{ financing.categoria ? financing.categoria.name : '' }}
      </span> <br />
    </div>

    <div style="border-top: none; border: 1px solid #000; padding: 4px 8px; line-height: 1.6">
      <span> a) qualificados (art.4º, II);</span><br />
      <span> b) não qualificados até R$ 20.000,00 (art.4º, <strong>caput</strong>);</span><br />
      <span> c) não qualificados acima de R$ 20.000,00 (art.4º, III);</span><br />
      <span> Nº da referência do pagamento da taxa de fiscalização:</span><br />
    </div>
  </div>
</template>

<style scoped>
.content {
  background-color: #fff;
  padding: 8px 24px 24px 24px;
  font-family: Arial, Helvetica, sans-serif;
}
.span-header {
  text-transform: uppercase; 
  font-weight: 300; 
  font-size: 10px;
}
.title {
  color: #000; 
  font-size: 12px;
  text-transform: lowercase;
}
.title :first-letter { text-transform: capitalize; }
.value-desc {
  margin-left: 20px;
  margin-bottom: 0;
  list-style-type: none;
}
</style>
