import store from '@/store/index';
import Vue from 'vue';
import InstancePlugin from '@/plugins/InstancePlugin';
Vue.use(InstancePlugin);

const modalidade = process.env.VUE_APP_TYPE;

// GeneralViews
import DashboardLayout from '@/layout/dashboard/DashboardLayout.vue';
import NotFound from '@/pages/NotFoundPage.vue';

// Admin pages
const Dashboard = Vue.prototype.$CWLGetPageInstance('Dashboard.vue');
const Profile = Vue.prototype.$CWLGetPageInstance('Profile.vue');
const IncomeReport = Vue.prototype.$CWLGetPageInstance('IncomeReport/IncomeReport.vue');
const SignIn = Vue.prototype.$CWLGetPageInstance('Authentication/SignIn.vue');
const SignUp = Vue.prototype.$CWLGetPageInstance('Authentication/SignUp.vue');

const SocialAgent = Vue.prototype.$CWLGetPageInstance('SocialTransformation/SocialAgent.vue');
const EditSocialAgent = Vue.prototype.$CWLGetPageInstance('SocialTransformation/EditSocialAgent.vue');

const Users = Vue.prototype.$CWLGetPageInstance('Operationals/Users.vue');
const AddUser = Vue.prototype.$CWLGetPageInstance('Operationals/AddUser.vue');
const EditUser = Vue.prototype.$CWLGetPageInstance('Operationals/EditUser.vue');

const Banks = Vue.prototype.$CWLGetPageInstance('Banks/Banks.vue');
const AddBank = Vue.prototype.$CWLGetPageInstance('Banks/AddBank.vue');
const EditBank = Vue.prototype.$CWLGetPageInstance('Banks/EditBank.vue');

const Bancarizadores = Vue.prototype.$CWLGetPageInstance('Bancarizadores/Bancarizadores.vue');
const AddBancarizador = Vue.prototype.$CWLGetPageInstance('Bancarizadores/AddBancarizador.vue');
const EditBancarizador = Vue.prototype.$CWLGetPageInstance('Bancarizadores/EditBancarizador.vue');

const Categorias = Vue.prototype.$CWLGetPageInstance('Categorias/Categorias.vue');
const AddCategoria = Vue.prototype.$CWLGetPageInstance('Categorias/AddCategoria.vue');
const EditCategoria = Vue.prototype.$CWLGetPageInstance('Categorias/EditCategoria.vue');

const SegmentInterest = Vue.prototype.$CWLGetPageInstance('SegmentInterest/SegmentInterest');
const EditSegment = Vue.prototype.$CWLGetPageInstance('SegmentInterest/EditSegment');
const AddSegment = Vue.prototype.$CWLGetPageInstance('SegmentInterest/AddSegment');

const States = Vue.prototype.$CWLGetPageInstance('States/States');
const EditState = Vue.prototype.$CWLGetPageInstance('States/EditState');
const AddState = Vue.prototype.$CWLGetPageInstance('States/AddState');

const Financing = Vue.prototype.$CWLGetPageInstance('Financing/Financing');
const AddFinancing = Vue.prototype.$CWLGetPageInstance('Financing/AddFinancing');
const EditFinancing = Vue.prototype.$CWLGetPageInstance('Financing/EditFinancing');
const ResumeFinancing = Vue.prototype.$CWLGetPageInstance('Financing/ResumeFinancing');
const Contributions = Vue.prototype.$CWLGetPageInstance('Financing/Contributions');
const ConfirmOperations = Vue.prototype.$CWLGetPageInstance('Financing/ConfirmOperations');
const ComplementarEmpresa = Vue.prototype.$CWLGetPageInstance('Financing/ComplementarEmpresa');

const Values = Vue.prototype.$CWLGetPageInstance('Values/Values');
const AddValue = Vue.prototype.$CWLGetPageInstance('Values/AddValue');
const EditValue = Vue.prototype.$CWLGetPageInstance('Values/EditValue');

const InvestimentsProfile = Vue.prototype.$CWLGetPageInstance('InvestmentsProfile/InvestmentsProfile');
const AddInvestment = Vue.prototype.$CWLGetPageInstance('InvestmentsProfile/AddInvestment');
const EditInvestment = Vue.prototype.$CWLGetPageInstance('InvestmentsProfile/EditInvestment');

const Attachments = Vue.prototype.$CWLGetPageInstance('Attachments/AttachmentsList');
const AddAtachment = Vue.prototype.$CWLGetPageInstance('Attachments/AddAttachment');

const TermsOfUseList = Vue.prototype.$CWLGetPageInstance('TermsOfUse/TermsOfUseList');
const AddTermOfUse = Vue.prototype.$CWLGetPageInstance('TermsOfUse/AddTermOfUse');
const TermOfUse = Vue.prototype.$CWLGetPageInstance('TermsOfUse/TermOfUse');

const InvestorsList = Vue.prototype.$CWLGetPageInstance('Investors/InvestorsList');
const InvestorDetail = Vue.prototype.$CWLGetPageInstance('Investors/InvestorDetail');

const CampaignDetails = Vue.prototype.$CWLGetPageInstance('Campaigns/CampaignDetails');

const Logs = Vue.prototype.$CWLGetPageInstance('Logs/Logs');

const DocumentsList = Vue.prototype.$CWLGetPageInstance('Documents/DocumentsList');
const AttachIdentificationDocument = Vue.prototype.$CWLGetPageInstance('Documents/AttachIdentificationDocument');
const AttachIncomeDocument = Vue.prototype.$CWLGetPageInstance('Documents/AttachIncomeDocument');
const AttachAddressDocument = Vue.prototype.$CWLGetPageInstance('Documents/AttachAddressDocument');
const AttachOtherDocument = Vue.prototype.$CWLGetPageInstance('Documents/AttachOtherDocument');

const ReportsList = Vue.prototype.$CWLGetPageInstance('Reports/ReportsList');
const AnnualReport = Vue.prototype.$CWLGetPageInstance('Reports/AnnualReport');
const PaymentReport = Vue.prototype.$CWLGetPageInstance('Reports/PaymentReport');
const OffersReport = Vue.prototype.$CWLGetPageInstance('Reports/OffersReport');
const CampaignGeneral = Vue.prototype.$CWLGetPageInstance('Reports/CampaignsGeneral');
const CampaignStatement = Vue.prototype.$CWLGetPageInstance('Reports/CampaignStatement');
const VencimentoCCBsGeral = Vue.prototype.$CWLGetPageInstance('Reports/VencimentoCCBsGeral');

const DynamicFields = Vue.prototype.$CWLGetPageInstance('DynamicFields/DynamicFields');
const AddDynamicField = Vue.prototype.$CWLGetPageInstance('DynamicFields/AddDynamicField');
const EditDynamicField = Vue.prototype.$CWLGetPageInstance('DynamicFields/EditDynamicField');

const Settings = Vue.prototype.$CWLGetPageInstance('Settings/Settings');
const SettingsIntegracaoClickSign = Vue.prototype.$CWLGetPageInstance('Settings/Integracoes/ClickSign');
const SettingsIntegracaoSafe2Pay = Vue.prototype.$CWLGetPageInstance('Settings/Integracoes/Safe2Pay');
const SettingsIntegracaoFiducia = Vue.prototype.$CWLGetPageInstance('Settings/Integracoes/Fiducia');
const SettingsIntegracaoSocinal = Vue.prototype.$CWLGetPageInstance('Settings/Integracoes/Socinal');

const Transactions = Vue.prototype.$CWLGetPageInstance('Transactions/Transactions');

const ForgotPassword = Vue.prototype.$CWLGetPageInstance('Authentication/ForgotPassword');

//Social Transformation
const RegisterSocialTransformationAgent = Vue.prototype.$CWLGetPageInstance('SocialTransformation/RegisterSocialTransformationAgent');

const beforeEnter = (to, from, next) => {
  const Claim = store.getters.userClaim.role;

  if (
    Claim !== 'master' &&
    Claim !== 'gestor' &&
    to.name !== 'Criar Campanha' &&
    to.name !== 'Criar Oferta' &&
    to.name !== 'Ver Campanha' &&
    to.name !== 'Ver Oferta' &&
    to.name !== 'Campanhas' &&
    to.name !== 'Ofertas' &&
    to.name !== 'Ver Campanha ' &&
    to.name !== 'Investidores'
  ) {
    next('dashboard');
  }

  next();
};

const routes = [
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/dashboard',
    children: [
      {
        path: '/login/:ref',
        component: SignIn,
        name: 'login-ref',
        meta: {
          layout: 'guest',
        },
      },
      {
        path: '/login',
        component: SignIn,
        name: 'login',
        meta: {
          layout: 'guest',
        },
      },
      {
        path: '/register/:ref',
        component: SignUp,
        name: 'register-ref',
        meta: {
          layout: 'guest',
        },
      },
      {
        path: '/register',
        component: SignUp,
        name: 'register',
        meta: {
          layout: 'guest',
        },
      },
      {
        path: '/forgot-password',
        component: ForgotPassword,
        name: 'forgot-password',
        meta: {
          layout: 'guest',
        },
      },
      {
        path: '/term-of-use',
        name: 'term-of-use',
        component: TermOfUse,
        meta: {
          layout: 'full',
        },
      },
      {
        path: '/dynamic-fields',
        component: DynamicFields,
        name: 'Campos Dinâmicos',
      },
      {
        path: '/add-dynamic-field',
        component: AddDynamicField,
        name: 'Criar Campo Dinâmico',
      },
      {
        path: '/edit-dynamic-field/:id',
        component: EditDynamicField,
        name: 'Editar Campo Dinâmico',
      },
      {
        path: '/settings',
        component: Settings,
        name: 'Configurações da plataforma',
      },
      {
        path: '/settings/clicksign',
        component: SettingsIntegracaoClickSign,
        name: 'Integração com ClickSign',
      },
      {
        path: '/settings/safe2pay',
        component: SettingsIntegracaoSafe2Pay,
        name: 'Integração com Safe2Pay',
      },
      {
        path: '/settings/fiducia',
        component: SettingsIntegracaoFiducia,
        name: 'Integração com Fidúcia',
      },
      {
        path: '/settings/socinal',
        component: SettingsIntegracaoSocinal,
        name: 'Integração com Socinal',
      },
      {
        path: '/transactions/:type/:id',
        component: Transactions,
        name: 'Transações',
      },
      {
        path: 'dashboard',
        name: 'dashboard',
        component: Dashboard,
        // beforeEnter,
      },
      {
        path: 'profile',
        name: 'Meus Dados',
        component: Profile,
      },
      {
        path: 'incomeReport',
        name: 'Informe de Rendimentos',
        component: IncomeReport,
      },
      {
        path: 'profile/:id',
        name: 'Meus Dados',
        component: Profile,
      },
      {
        path: 'users',
        name: 'Usuários',
        component: Users,
        beforeEnter,
      },
      {
        path: 'social-transformation/agents',
        name: 'Agentes (API3)',
        component: SocialAgent,
      },
      {
        path: 'social-transformation/agents/:id',
        name: 'Editar Agente (API3)',
        component: EditSocialAgent,
      },
      {
        path: 'add-user',
        name: 'Adicionar Usuário',
        component: AddUser,
        beforeEnter,
      },
      {
        path: 'edit-user/:id',
        name: 'Editar Usuário',
        component: EditUser,
        beforeEnter,
      },
      {
        path: 'banks',
        name: 'Bancos',
        component: Banks,
        beforeEnter,
      },
      {
        path: 'bancarizadores',
        name: 'Bancarizadores',
        component: Bancarizadores,
        beforeEnter,
      },
      {
        path: 'add-bancarizador',
        name: 'Adicionar Bancarizador',
        component: AddBancarizador,
        beforeEnter,
      },
      {
        path: 'edit-bancarizador/:id',
        name: 'Editar Bancarizador',
        component: EditBancarizador,
        beforeEnter,
      },
      {
        path: 'categorias',
        name: 'Categorias',
        component: Categorias,
        beforeEnter,
      },
      {
        path: 'add-categoria',
        name: 'Adicionar Categoria',
        component: AddCategoria,
        beforeEnter,
      },
      {
        path: 'edit-categoria/:id',
        name: 'Editar Categoria',
        component: EditCategoria,
        beforeEnter,
      },
      {
        path: 'add-bank',
        name: 'Adicionar Banco',
        component: AddBank,
        beforeEnter,
      },
      {
        path: 'edit-bank/:id',
        name: 'Editar Banco',
        component: EditBank,
        beforeEnter,
      },
      {
        path: 'segment-interest',
        name: 'Segmentos',
        component: SegmentInterest,
        beforeEnter,
      },
      {
        path: 'add-segment',
        name: 'Adicionar Segmento',
        component: AddSegment,
        beforeEnter,
      },
      {
        path: 'edit-segment/:id',
        name: 'Editar Segmento',
        component: EditSegment,
        beforeEnter,
      },
      {
        path: 'states',
        name: 'Estados',
        component: States,
        beforeEnter,
      },
      {
        path: 'add-state',
        name: 'Adicionar Estado',
        component: AddState,
        beforeEnter,
      },
      {
        path: 'edit-state/:id',
        name: 'Editar Estado',
        component: EditState,
        beforeEnter,
      },
      {
        path: 'values',
        name: 'Valores',
        component: Values,
        beforeEnter,
      },
      {
        path: 'add-values',
        name: 'Adicionar Valores',
        component: AddValue,
        beforeEnter,
      },
      {
        path: 'edit-values/:id',
        name: 'Editar Valores',
        component: EditValue,
        beforeEnter,
      },
      {
        path: 'investments-profile',
        name: 'Perfil de Investimentos',
        component: InvestimentsProfile,
        beforeEnter,
      },
      {
        path: 'add-investment',
        name: 'Adicionar Investimento',
        component: AddInvestment,
        beforeEnter,
      },
      {
        path: 'edit-investment/:id',
        name: 'Editar Investimento',
        component: EditInvestment,
        beforeEnter,
      },
      {
        path: 'financing',
        name: modalidade == 'doacao' ? 'Campanhas' : 'Ofertas',
        component: Financing,
        beforeEnter,
      },
      {
        path: 'add-financing',
        name: modalidade == 'doacao' ? 'Criar Campanha' : 'Criar Oferta',
        component: AddFinancing,
        beforeEnter,
      },
      {
        path: 'resume-financing/:id',
        name: modalidade == 'doacao' ? 'Ver Campanha' : 'Ver Oferta',
        component: ResumeFinancing,
        beforeEnter,
      },
      {
        path: 'edit-financing/:id',
        name: modalidade == 'doacao' ? 'Ver Campanha' : 'Ver Oferta',
        component: EditFinancing,
        beforeEnter,
      },
      {
        path: 'edit-financing/:id/confirm-operations',
        name: 'Confirmar Operação de Investimento',
        component: ConfirmOperations,
        beforeEnter,
      },
      {
        path: 'edit-financing/:id/fill-company',
        name: 'Complementar Empresa',
        component: ComplementarEmpresa
      },
      {
        path: 'contributions/:id',
        name: 'Ver Aportes',
        component: Contributions,
      },
      {
        path: 'attachments',
        name: 'Anexos',
        component: Attachments,
        beforeEnter,
      },
      {
        path: 'add-attachment',
        name: 'Adicionar Anexo',
        component: AddAtachment,
        beforeEnter,
      },
      {
        path: 'investors',
        name: 'Investidores',
        component: InvestorsList,
        beforeEnter,
      },
      {
        path: 'investor-detail/:id',
        name: 'Detalhes Investidor',
        component: InvestorDetail,
        beforeEnter,
      },
      {
        path: 'terms-of-use',
        name: 'Contratos',
        component: TermsOfUseList,
        beforeEnter,
      },
      {
        path: 'add-term-of-use',
        name: 'Adicionar Contrato',
        component: AddTermOfUse,
        beforeEnter,
      },
      {
        path: 'campaign-details/:id',
        name:
          modalidade == 'doacao'
            ? 'Detalhes da Campanha'
            : 'Detalhes da Oferta',
        component: CampaignDetails,
        // beforeEnter,
      },
      {
        path: 'logs',
        name: 'Logs',
        component: Logs,
        beforeEnter,
      },
      {
        path: '/social-transformation/register',
        name: 'Agente de Captação ICoOwner ODS/ESG',
        component: RegisterSocialTransformationAgent,
      },
      {
        path: 'documents-list',
        component: DocumentsList,
        children: [
          {
            path: '',
            name: 'Identificação',
            component: AttachIdentificationDocument,
          },
          {
            path: 'income-document',
            name: 'Renda',
            component: AttachIncomeDocument,
          },
          {
            path: 'address-document',
            name: 'Endereço',
            component: AttachAddressDocument,
          },
          {
            path: 'other-document',
            name: 'Outro',
            component: AttachOtherDocument,
          },
        ],
      },
      {
        path: 'documents-list/:id',
        component: DocumentsList,
        children: [
          {
            path: '',
            name: 'Identificação',
            component: AttachIdentificationDocument,
          },
          {
            path: 'income-document',
            name: 'Renda',
            component: AttachIncomeDocument,
          },
          {
            path: 'address-document',
            name: 'Endereço',
            component: AttachAddressDocument,
          },
          {
            path: 'other-document',
            name: 'Outro',
            component: AttachOtherDocument,
          },
        ],
      },
      {
        path: 'reports',
        name: '',
        component: ReportsList,
        beforeEnter,
        children: [
          {
            path: 'annual-report',
            name: 'Relatório Anual',
            component: AnnualReport,
          },
          {
            path: 'payment',
            name: 'Relatório Pagamento',
            component: PaymentReport,
          },
          {
            path: 'offers',
            name: 'Relatório Ofertas',
            component: OffersReport,
          },
          {
            path: 'campaigns-general',
            name: 'Relatório Geral',
            component: CampaignGeneral,
          },
          {
            path: 'vencimento-ccbs',
            name: 'Relatório Vencimento CCBs',
            component: VencimentoCCBsGeral,
          },
          {
            path: 'vencimento-ccbs/:id/:report',
            name: 'Relatório Analítico Vencimento CCB',
            component: CampaignDetails,
          },
          {
            path: 'campaign-statement/:id',
            name:
              modalidade == 'doacao'
                ? 'Relatório Campanha'
                : 'Relatório Oferta',
            component: CampaignStatement,
          },
        ],
      },
    ],
  },
  { path: '*', component: NotFound },
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;
